import React, { useState } from 'react'
import MyDataPicker from '../../../../app/MyDataPicker'
import _ from 'lodash'
import { useAppSelector } from '../../../../hooks/redux'
import { regExps } from '../../../../constants'
import { type RootState } from '../../../../store/store'

import CustomSelect from '../../../../app/custom/selects/CustomSelect'
import AreaInput from '../../../../app/custom/inputs/AreaInput'
import { Space, Switch } from 'antd'
import { getBanks } from '../../../../functions/getDataForSelects/dataFunctions'
import { type Control, type FieldErrors, type FieldValues, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'
import CustomFormLable from '../../../../app/custom/customModalUtils/CustomFormLable'

interface AgentToBSOOfferOfferInfoProps {
    setValue: UseFormSetValue<FieldValues>
    errors: FieldErrors<any>
    register: UseFormRegister<any>
    control: Control<any>
    offerID: number
    unitID: number
}

export default function AgentToBSOOfferOfferInfo ({
    setValue,
    errors,
    register,
    control,
    offerID,
    unitID
}: AgentToBSOOfferOfferInfoProps): JSX.Element {
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = units.find((unitItem) => unitItem.id === unitID)
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === offerID)

    const isSale = unit?.attributes?.Marketing?.Use === 'For sale'

    const [hasBankPreApproval, setHasBankPreApproval] = useState(false)

    const rowTypeOffer = {
        id: 'Type.Offer',
        content: ['Offer', 'AED', 'Client’s offer for the property'],
        element: 'input-group',
        required: true,
        regexp: regExps.isNumber,
        defaultValue: offer?.attributes?.Type?.[0]?.Offer ?? undefined
    }
    const rowTypeContractStartDate = {
        id: 'Type.ContractStartDate',
        content: ['Contract Start Date', 'Input contract start date'],
        dateAfter: true,
        element: 'input-date',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.ContractStartDate ?? undefined
    }
    const rowTypePayments = {
        id: 'Type.Payments',
        content: ['Payment installments', 'Select count'],
        selectors: ['1', '2', '3', '4', '6', '12'],
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.Payments ?? undefined
    }
    const rowTypeDuration = {
        id: 'Type.Duration',
        content: ['Contract Duration', 'Select contract duration'],
        selectors: _.range(6, 25).map(item => ({ label: `${item} Months`, value: item })),
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.Duration ?? undefined
    }
    const rowBankName = {
        id: 'Type.BankName',
        content: ['Bank Name', 'Select bank'],
        selectors: getBanks(),
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.BankName ?? undefined
    }
    const rowPreApprovedAmount = {
        id: 'Type.PreApprovedAmount',
        content: ['Pre-Approved Amount', 'AED', 'Amount'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.PreApprovedAmount ?? undefined
    }
    const rowOfferType = {
        id: 'Type.Type',
        content: ['Offer Type', 'Select option'],
        selectors: ['Cash Buyer', 'Mortgage Buyer'],
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.Type ?? undefined
    }
    return (
        <>

            <div className="fv-row  mt-8 p-0 w-100">

                <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                    <CustomFormLable row={rowTypeOffer} />
                    <div className="input-group">
                        <input
                            {...register(rowTypeOffer.id, {
                                required: `Field "${String(rowTypeOffer.content?.[0])}" is required`,
                                validate: (value: string) => {
                                    let result: boolean = true
                                    if (rowTypeOffer.regexp != null) {
                                        result = new RegExp(rowTypeOffer.regexp).test(value)
                                    }
                                    // handleOfferChange(value)
                                    return result
                                }
                            })}
                            name={rowTypeOffer.id}
                            className="form-control form-control-solid form-control-lg"
                            type="text"
                            placeholder={rowTypeOffer.content?.[2]}
                            defaultValue={rowTypeOffer?.defaultValue}
                        />
                        <span className="input-group-text border-0">{rowTypeOffer.content?.[1]}</span>
                    </div>
                    {
                        _.get(errors, rowTypeOffer.id) != null && (
                            _.get(errors, rowTypeOffer.id)?.type === 'validate'
                                ? <div className="text-danger">{`${String(rowTypeOffer?.content?.[0])} must be valid `} </div>
                                : <div className="text-danger">{String(_.get(errors, rowTypeOffer.id)?.message)}</div>
                        )
                    }
                </div>

                {!isSale
                    ? <>
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowTypeContractStartDate} />
                            <MyDataPicker control={control} register={register} row={rowTypeContractStartDate} setValue={setValue} />
                            {_.get(errors, rowTypeContractStartDate.id) != null && (
                                <div className="text-danger">{String(_.get(errors, rowTypeContractStartDate.id)?.message)}</div>
                            )}
                        </div>
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowTypePayments} />
                            <CustomSelect control={control} register={register} data={rowTypePayments} setValue={setValue} />
                            {
                                _.get(errors, rowTypePayments.id) != null && (
                                    <div className="text-danger my-2">{String(_.get(errors, rowTypePayments.id)?.message)}</div>
                                )
                            }
                        </div>

                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowTypeDuration} />
                            <CustomSelect control={control} register={register} data={rowTypeDuration} setValue={setValue} />
                            {
                                _.get(errors, rowTypeDuration.id) != null && (
                                    <div className="text-danger my-2">{String(_.get(errors, rowTypeDuration.id)?.message)}</div>
                                )
                            }
                        </div>

                    </>
                    : <>
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowOfferType} />
                            <CustomSelect control={control} register={register} data={rowOfferType} setValue={setValue} />
                            {
                                _.get(errors, rowOfferType.id) != null && (
                                    <div className="text-danger my-2">{String(_.get(errors, rowOfferType.id)?.message)}</div>
                                )
                            }
                        </div>
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowBankName} />
                            <CustomSelect control={control} register={register} data={rowBankName} setValue={setValue} />
                            {
                                _.get(errors, rowBankName.id) != null && (
                                    <div className="text-danger my-2">{String(_.get(errors, rowBankName.id)?.message)}</div>
                                )
                            }
                        </div>
                        <div className="separator separator-dashed my-4"></div>
                        <div className="fv-row my-2 m-0 p-0 w-100">
                            <div className="d-flex flex-wrap align-items-center">
                                <div >
                                    <div className="fs-6 fw-bold mb-1 text-uppercase">Client has bank pre-approval?</div>
                                </div>
                                <div className="ms-auto">
                                    <Space direction="vertical">
                                        <Switch unCheckedChildren="NO" defaultChecked={hasBankPreApproval}
                                            checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                            onChange={(value) => {
                                                setHasBankPreApproval(value)
                                                setValue(rowPreApprovedAmount.id, undefined)
                                            }}
                                        />
                                    </Space>
                                </div>
                            </div>
                        </div>
                        {
                            hasBankPreApproval
                                ? <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                    <CustomFormLable row={rowPreApprovedAmount} />
                                    <div className="input-group">
                                        <input
                                            {...register(rowPreApprovedAmount.id, {
                                                required: `Field "${rowPreApprovedAmount.content?.[0]}" is required`
                                            })}
                                            name={rowPreApprovedAmount.id}
                                            className="form-control form-control-solid form-control-lg"
                                            type="text"
                                            placeholder={rowPreApprovedAmount.content?.[2]}
                                        />
                                        <span className="input-group-text border-0">{rowPreApprovedAmount.content?.[1]}</span>
                                    </div>
                                    {
                                        _.get(errors, rowPreApprovedAmount.id) != null && (
                                            _.get(errors, rowPreApprovedAmount.id)?.type === 'validate'
                                                ? <div className="text-danger">{`${rowPreApprovedAmount.content?.[0]} must be valid `} </div>
                                                : <div className="text-danger">{String(_.get(errors, rowPreApprovedAmount.id)?.message)}</div>
                                        )
                                    }
                                </div>
                                : <></>
                        }
                        <div className="separator separator-dashed my-4"></div>
                    </>
                }
            </div >

        </>

    )
}
