import Approved from '../statuses/Approved'
import Canceled from '../statuses/Cenceled'
import React from 'react'
import Completed from '../statuses/Completed'
import InProcessScheduler from '../statuses/InProcessScheduler'
import Rejected from '../statuses/Rejected'
import Confirmed from '../statuses/Confirmed'

export function SchedulerStatus(stringArray: string[], fontsize = ' '): JSX.Element {

    if (stringArray && stringArray.length > 0) {
        if (stringArray.includes('Approved')) return <Approved fontsize={fontsize} />
        if (stringArray.includes('Completed')) return <Completed fontsize={fontsize} />
        if (stringArray.includes('Confirmed')) return <Confirmed fontsize={fontsize} />
        if (stringArray.includes('Canceled')) return <Canceled fontsize={fontsize} />
        if (stringArray.includes('Rejected')) return <Rejected fontsize={fontsize} />
        if (stringArray[0].includes('Rescheduled')) return <div className={`badge badge-light-info  border border-info text-truncate mw-100 d-inline-block ${fontsize}`}>{stringArray?.[0]}</div>
        if (stringArray.includes('Advertisement started') || stringArray.includes('Links approved') || stringArray.includes('Links added') || stringArray.includes('Ads removed')) return <div className={`badge badge-light-success  border border-success text-truncate mw-100 d-inline-block ${fontsize}`}>{stringArray?.[0]}</div>
        if (stringArray.includes('Reschedule Reason') || stringArray.includes('Links rejected') || stringArray.includes('Ads removing rejected') || stringArray.includes('A Form rejected') || stringArray[0].includes('Canceled')) return <div className={`badge badge-light-danger  border border-danger text-truncate mw-100 d-inline-block ${fontsize}`}>{stringArray?.[0]}</div>
    }

    return <InProcessScheduler status={stringArray?.[0]} fontsize={fontsize} />
}
