
import React, { useEffect, useState } from 'react'
import { Steps } from 'antd'
import { type IAdvertise } from '../../../../store/slices/advertisesSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'

import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import { type TimelineItem } from '.././ViewingTimeline'
import Icon from '../../../icon/Icon'

interface IProps {
    advertise: IAdvertise
    unit: Unit
}

function AdvertiseTimelineRent({ advertise, unit }: IProps): JSX.Element {
    let process = 1

    const unitStatuses = unit.attributes.Statuses
    const advertiseStatuses = advertise?.attributes?.Statuses

    let textProcessFirst = 'Unit Documents are Ready'
    let descriptionFirst = 'Unit documents have been sent to your registered email and feel free to download them.'

    //  Specify the promotional countdown date and Add promotional links

    const textProcessSecond = 'Add advertisements links'
    const descriptionSecond = 'Please provide the links to advertisements that you are doing for this unit'

    const textProcessThird = 'Wait for advertisements links approval' // 'Awaiting approval links'
    const descriptionThird = 'Our specialist will approve your advertisements links soon. You also can provide new links during this process that have been created by you recently and are not in the submitted list' // 'You can also provide links to ads for this block that have appeared recently and are not on this list.'
    const textProcessFourth = 'Advertisement completed successfully' // 'Your advertisement has been successfully completed'
    const descriptionFourth = 'Congratulations on another completed advertisement! Let’s make a new one with the BSO Club!'
    let stepApprovedOrRejected = 'Your advertisements links were approved by BSO' // 'Your advertise link(s) approved!'
    let descriptionApprovedOrRejected = 'Feel free to make your advertisements'

    const stepWaitingRemoving = 'Your advertisement is over' // 'Awaiting of the advertisement removing'
    const descriptionWaitingRemoving = 'Please remove all ads that you submitted to 3-party advertisement platforms and submit this advertisement to final checks through the app'

    let stepApprovedOrRejectedRemoving = 'Your advertisement successfully passed the final checks'
    let descriptionApprovedOrRejectedRemoving = 'Feel free to close this advertisement' // ' Your ads have been successfully removed.'

    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number) => {
        if (process + 1 > step || process === 6) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }

    const isRejectedStatuses = (advertise?.attributes?.Statuses.includes('Rejected') || advertise?.attributes?.Statuses.includes('Links rejected') || advertise?.attributes?.Statuses.includes('Advertisement closing rejected'))

    if (advertiseStatuses?.includes('In-process')) {
        process = 0
    } else if (advertiseStatuses?.includes('Docs received')) {
        process = 1
    } else if (advertiseStatuses?.includes('Links added')) {
        process = 2
    } else if (advertiseStatuses?.includes('Links rejected')) {
        process = 3
        stepApprovedOrRejected = 'Your advertisements links was rejected by BSO.' // 'Link(s) rejected! Edit your links'
        descriptionApprovedOrRejected = 'Please review your links and resubmit the right one'
    } else if (advertiseStatuses?.includes('Links approved') || advertiseStatuses?.includes('Confirmed')) {
        process = 3
        stepApprovedOrRejected = 'Your advertisement confirmed by BSO'
        descriptionApprovedOrRejected = 'Feel free to make your advertisement. After the advertisement ends please delete inactive/expired advertising links and published posts'
    } else if (advertiseStatuses?.includes('Advertisement closed')) {
        process = 4
    } else if (advertiseStatuses?.includes('Ads removing rejected')) {
        process = 5
        stepApprovedOrRejectedRemoving = 'Ad removal check failed, we found an active ads'
        descriptionApprovedOrRejectedRemoving = 'Please be sure that you delete all published posts in 3-party advertisement platforms related to you and this unit'
    } else if (advertiseStatuses?.includes('Ads removed')) {
        process = 5
        stepApprovedOrRejectedRemoving = 'Ad removal status update'// 'Waiting of the advertise removing'
    } else if (advertiseStatuses?.includes('Completed')) {
        process = 6
    } else if (advertiseStatuses?.includes('Canceled')) {
        process = 0
        textProcessFirst = 'This unit has been sold'
        descriptionFirst = ""
    } else {
        process = 0
        textProcessFirst = 'Your advertise during the process'
    }

    const advTimeline: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'> {textProcessFirst} </div>
                    <div className={'d-flex align-items-center'}>
                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2 ">Status</div>}
                        <div className="mt-2">{process === 0 ? SchedulerStatus(advertiseStatuses?.includes('Canceled') ? ["Unit is sold"] : advertise?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                    </div> </div>
            ),
            description: descriptionFirst,
            ...(advertiseStatuses?.includes('Canceled') ? { status: 'finish' } : {})
        },
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'> {textProcessSecond} </div>
                    <div className={'d-flex align-items-center'}>
                        {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mt-1">{process === 1 ? SchedulerStatus(advertise?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                    </div></div>
            ),
            description: descriptionSecond
        },
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>
                        {textProcessThird}   </div>
                    <div className={'d-flex align-items-center'}>
                        {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2 my-0">Status</div>}
                        <div className="mt-1">{process === 2 ? SchedulerStatus(advertise?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                    </div>
                </div>
            ),
            description: descriptionThird
        },

        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>    {stepApprovedOrRejected} </div>
                    {isRejectedStatuses && advertise?.attributes?.RejectReason && advertise?.attributes?.RejectReason.length > 0 &&
                        <>
                            <label className="form-label fw-bold text-dark m-0 fs-5 ">
                                Reject Reason :
                            </label>
                            <span> {advertise?.attributes?.RejectReason} </span></>
                    }
                    <div className={'d-flex align-items-center'}>
                        {process === 3 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mt-1">{process === 3 ? SchedulerStatus(advertise?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                    </div>
                </div>
            ),
            description: descriptionApprovedOrRejected
        },

        {
            title: (
                <div className='lh-sm mb-1'>
                    {stepWaitingRemoving}
                    <div className={'d-flex align-items-center'}>
                        {process === 4 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mt-1">{process === 4 ? SchedulerStatus(advertise?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                    </div>
                </div>
            ),
            description: descriptionWaitingRemoving
        },
        {
            title: (
                <div className='lh-sm mb-1'>
                    {stepApprovedOrRejectedRemoving}
                    <div className={'d-flex align-items-center'}>
                        {process === 5 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mt-1">{process === 5 ? SchedulerStatus(advertise?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                    </div>
                </div>
            ),
            description: descriptionApprovedOrRejectedRemoving
        },
        {
            title: (
                <div className='lh-sm mb-1'>
                    {textProcessFourth}
                    <div className={'d-flex align-items-center'}>
                        {process === 6 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mt-1">{process === 6 ? SchedulerStatus(advertise?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                    </div>
                </div>
            ),
            description: descriptionFourth,
            status: process === 6 ? 'finish' : undefined
        } satisfies TimelineItem
    ]

    const [sliceSteps, setSliceSteps] = useState([advTimeline[process]])

    useEffect(() => {
        advTimeline.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])

    return (
        <div>

            {<Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
            />}

            <div className={'d-flex'} style={{ justifyContent: process === 0 || process === 6 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([advTimeline[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...advTimeline].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 6 && !advertise?.attributes?.Statuses?.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([advTimeline[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...advTimeline].slice(process)); setLine('next') }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdvertiseTimelineRent
