import { type AppDispatch, type RootState } from '../../../store'
import { dataFetchingFinish, modalSlice } from '../../../slices/modalSlice'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import QueryString from 'qs'
import { offersSlice } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { setFilterNavigate } from '../../../slices/scheduleSlice'
import { deleteAgentToBSOOfferCommunicationStorageInfo } from './agentToBSOOfferCommunicationStorage'
import { sendNotification } from '../../offerActions'
import Swal from "sweetalert2";
import {swalOptions} from "../../../../functions/swalOptions";

export const makeOfferAgentToBSOAdditionalInfo = (unitID: number, offerID: number) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(modalSlice.actions.dataFetchingStart())
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const unit = getState().units.units.find((unitItem) => unitItem.id === unitID)
        const offer = getState().offers?.offers?.find((offer) => offer.id === Number(offerID))
        const fitsSpecialRequirements = data.FitsSpecialRequest === true
        if (!fitsSpecialRequirements) {
            const result = await Swal.fire({
                ...swalOptions('warning'),
                title: 'Are you sure you want to submit an offer that doen\'t fit the unit requirements?',
                text: 'Most likely it will be rejected',
                showConfirmButton: true,
                confirmButtonText: 'Go back to offer',
                showDenyButton: true,
                denyButtonText: 'Submit offer anyway'
            })
            if (result.isConfirmed || result.isDismissed) {
                return {
                    isSuccessful: true,
                    textNavigate: '',
                    textNotification: ''
                }
            }
        }
        if (unit != null && offer != null) {
            const isSale = unit?.attributes?.Marketing?.Use === 'For sale'
            const isCommercial = offer?.attributes?.Type?.[0]?.__component === 'offer.commercial'
            const offerType = {
                ...offer?.attributes?.Type?.[0],
                ...(
                    isSale || isCommercial
                        ? {
                            AdditionalNotes: data.Type.AdditionalNotes
                        }
                        : {
                            Occupation: data.Type.Occupation,
                            Employer: data.Type.Employer,
                            Occupants: data.Type.Occupants,
                            Pets: data.Type.Pets,
                            PetType: data.Type.Pets === true ? data.Type.PetType : null,
                            ...(
                                data.additional_info === true
                                    ? {
                                        InUAESince: data.Type.InUAESince,
                                        UAEBank: data.Type.UAEBank,
                                        AdditionalNotes: data.Type.AdditionalNotes,
                                        Smoking: data.Type.Smoking
                                    }
                                    : {
                                        InUAESince: null,
                                        UAEBank: null,
                                        AdditionalNotes: null,
                                        Smoking: null
                                    }
                            )
                        }
                )
            }
            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: offerID,
                query: QueryString.stringify({
                    populate: ['Type', 'Documents', 'User', 'Unit']
                }, {
                    encodeValuesOnly: true
                }),

                body: {
                    Type: [offerType],
                    FitsSpecialRequest: fitsSpecialRequirements
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${String(jwt)}`
                },
                body: JSON.stringify(bodyObject)
            })
            const responseJSON = await response.json()
            if (responseJSON.success === true) {
                if (responseJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(responseJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                    void sendNotification(new Date(new Date().getTime() + 10000), Number(getState().user.currentUser.id), responseJSON.response.data ?? {}, 'admin-offer-in-process', 'Offer - in process')
                    deleteAgentToBSOOfferCommunicationStorageInfo()
                    dispatch(setFilterNavigate('offers'))
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return {
                        textNavigate: '/agent/schedule',
                        isSuccessful: true,
                        textNotification: 'Offer information has been saved',
                        title: 'Success'
                    }
                }
            }
        }
    } catch (err: any) {
        console.error(`Error in makeOfferAgentToBSOAdditionalInfo ${String(err.message)}`)
    }
    dispatch(modalSlice.actions.dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Something went wrong. Try again later'
    }
}
