import QueryString from 'qs'
import { REACT_APP_BSO_USER_ID, USER_CATEGORY_KEY, USER_ID_KEY } from '../../../../urls'

export const unitsPopulate = [
    'Documents',
    'User',
    'Marketing',
    'Property',
    'Viewings',
    'Offers',
    'Advertises.Unit.id',
    'Advertises.User.id',
    'Utilities'
]

export const getQueryUnits = (): string => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)
    let unitsQueryObject = {}

    if (userCATEGORY === 'Agent') {
        unitsQueryObject = {
            populate: {
                KeysBookings: '*',
                ParkingSlots: '*',
                Marketing: '*',
                Utilities: '*',
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },
                //  {
                //     fields: ['id']
                // },
                Property: {
                    populate: {
                        Utilities: '*'
                    }
                },
                Viewings: {
                    populate: {
                        User: {
                            fields: ['id']
                        }
                    }
                },
                Unit: {
                    fields: ['id']
                },
                Offers: {
                    populate: {
                        Type: '*',
                        User: {
                            fields: ['id']
                        }
                    }
                },
                Advertises: {
                    populate: {
                        User: {
                            fields: ['id']
                        },
                        Unit: {
                            fields: ['id']
                        }
                    }
                },
                DisabledTimes: '*',
                SpecialRequest: '*'
            }
        }
    } else if (userCATEGORY === 'Landlord' && Number(userID) !== Number(REACT_APP_BSO_USER_ID)) {
        unitsQueryObject = {
            populate: {
                KeysBookings: '*',
                ParkingSlots: '*',
                Marketing: '*',
                Utilities: '*',
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },
                //  {
                //     fields: ['id']
                // },
                Property: {
                    populate: {
                        Utilities: '*'
                    }
                },
                Viewings: {
                    populate: {
                        User: {
                            fields: ['id']
                        }
                    }
                },
                Unit: {
                    fields: ['id']
                },
                Offers: {
                    populate: {
                        Type: '*',
                        User: {
                            fields: ['id']
                        }
                    }
                },
                Advertises: {
                    populate: {
                        User: {
                            fields: ['id']
                        },
                        Unit: {
                            fields: ['id']
                        }
                    }
                },
                Landlords: {
                    populate: {
                        Landlord: {
                            populate: [
                                'Type',
                                'ContactInfo',
                                'Documents',
                                'BankDetails',
                                'PowerOfAttorney'
                            ]
                        },
                        Representatives: {
                            populate: [
                                'Documents',
                                'ContactInfo'
                            ]
                        }
                    }
                },
                DisabledTimes: '*',
                SpecialRequest: '*'
            }
        }
    } else if (Number(userID) === Number(REACT_APP_BSO_USER_ID)) {
        unitsQueryObject = {
            populate: {
                KeysBookings: '*',
                ParkingSlots: '*',
                Marketing: '*',
                Utilities: '*',
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },
                //  {
                //     fields: ['id']
                // },
                Property: {
                    populate: {
                        Utilities: '*'
                    }
                },
                Viewings: {
                    populate: {
                        User: {
                            fields: ['id']
                        }
                    }
                },
                Unit: {
                    fields: ['id']
                },
                Offers: {
                    populate: {
                        Type: '*',
                        User: {
                            fields: ['id']
                        }
                    }
                },
                Advertises: {
                    populate: {
                        User: {
                            fields: ['id']
                        },
                        Unit: {
                            fields: ['id']
                        }
                    }
                },
                Landlords: {
                    populate: {
                        Landlord: {
                            populate: [
                                'Type',
                                'ContactInfo',
                                'Documents',
                                'BankDetails',
                                'PowerOfAttorney'
                            ]
                        },
                        Representatives: {
                            populate: [
                                'Documents',
                                'ContactInfo'
                            ]
                        }
                    }
                },
                DisabledTimes: '*',
                SpecialRequest: '*'
            }
        }
    }
    const unitsQuery = QueryString.stringify(unitsQueryObject, { encode: false })

    return unitsQuery
}
