import { createSlice } from '@reduxjs/toolkit'
interface ModalState {
    loading: boolean
    deleting: boolean
    mainButtonOpen: boolean
    isUserVerified: boolean
    costomSelectOpen: boolean
    mainUnitFiltersOpen: boolean
    tuttorialsButtonOpen: boolean
    beforeLoadRERA: boolean
    drawerOpen: string | undefined
    dynamicDropDown: Array<{ label: string, value: any }>
    images: any[]
    documents: any[]
    initialData: {
        modal: any
        initialState: any
    }
    scannedData: Record<string, any>
    buttonSubscritionsOpen: boolean
    buttonOrdersHistoryOpen: boolean
    dateChooseViewing: Date | null
    rescheduleReason?: string
    showPopupAddOnsAreas: boolean
    showPopupAddOnsBricks: boolean
    showPopupScheduleFilters: boolean
    showButtonBuyCoinsOnAdvertise: boolean
    orderListScrollPosition: number
    previewVisible: boolean
    resendInProcess: boolean
    isBricksOpen: boolean
    isAreasOpen: boolean
    isAreasForBuyBricks: boolean
    isPreviousPathChooseAreas: boolean
    scheduleFilters: Record<string, any>

    buttonDriverAddOnsParent: boolean
    buttonDriverExtendLocation: boolean

}

const initialState: ModalState = {
    loading: false,

    isUserVerified: false,
    deleting: false,
    mainButtonOpen: false,
    costomSelectOpen: false,
    mainUnitFiltersOpen: false,
    tuttorialsButtonOpen: false,
    beforeLoadRERA: false,
    drawerOpen: undefined,
    dynamicDropDown: [],
    images: [],
    documents: [],
    initialData: {
        modal: null,
        initialState: null
    },
    scannedData: {},
    buttonSubscritionsOpen: false,
    buttonOrdersHistoryOpen: false,
    dateChooseViewing: null,
    rescheduleReason: undefined,
    showPopupAddOnsAreas: false,
    showPopupAddOnsBricks: false,
    showButtonBuyCoinsOnAdvertise: false,
    showPopupScheduleFilters: false,
    orderListScrollPosition: 0,
    previewVisible: false,
    resendInProcess: false,
    isBricksOpen: false,
    isAreasOpen: false,
    isAreasForBuyBricks: false,
    isPreviousPathChooseAreas: false,
    scheduleFilters: {
        sort: 'all',
        unreaded: false
    },
    buttonDriverAddOnsParent: false,
    buttonDriverExtendLocation: false
}

export const modalSlice = createSlice({
    name: 'modalsOptions',
    initialState,
    reducers: {
        setIsUserVerified (state, action) {
            state.isUserVerified = action.payload
        },

        deletingSomething (state, action) {
            state.deleting = action.payload
        },

        loadingRERAStart (state) {
            state.beforeLoadRERA = true
        },
        loadingRERAEnd (state) {
            state.beforeLoadRERA = false
        },
        dataFetchingStart (state) {
            state.loading = true
        },
        dataFetchingFinish (state) {
            state.loading = false
        },
        mainButtonOpening (state) {
            state.mainButtonOpen = true
        },
        mainButtonClosing (state) {
            state.mainButtonOpen = false
        },
        mainUnitFiltersOpening (state) {
            state.mainUnitFiltersOpen = true
        },
        mainUnitFiltersClosing (state) {
            state.mainUnitFiltersOpen = false
        },
        costomSelectOpening (state) {
            state.costomSelectOpen = true
        },
        costomSelectClosing (state) {
            state.costomSelectOpen = false
        },
        tuttorialsButtonOpening (state) {
            state.tuttorialsButtonOpen = true
        },
        tuttorialsButtonClosing (state) {
            state.tuttorialsButtonOpen = false
        },
        setInitialData (state, action) {
            state.initialData = action.payload
        },
        dropInitialData (state) {
            state.initialData = {
                modal: null,
                initialState: null
            }
        },
        setScannedData (state, action) {
            state.scannedData = action.payload
        },
        dropScannedData (state) {
            state.scannedData = {}
        },
        setImages (state, action) {
            state.images = action.payload
        },
        setDrawerOpen (state, action) {
            state.drawerOpen = action.payload
        },
        setDynamicDropDown (state, action) {
            state.dynamicDropDown = action.payload
        },
        setDocuments (state, action) {
            state.documents = action.payload
        },
        dropModalSlice () {
            return initialState
        },
        subscriptionsButtonOpening (state) {
            state.buttonSubscritionsOpen = true
        },
        subscriptionsButtonClosing (state) {
            state.buttonSubscritionsOpen = false
        },
        ordersHistoryButtonOpening (state) {
            state.buttonOrdersHistoryOpen = true
        },
        ordersHistoryButtonClosing (state) {
            state.buttonOrdersHistoryOpen = false
        },
        setDateChooseViewing (state, action) {
            state.dateChooseViewing = action.payload
        },
        setRescheduleReason (state, action) {
            state.rescheduleReason = action.payload
        },
        setShowPopupAddOnsAreas (state, action) {
            state.showPopupAddOnsAreas = action.payload
        },
        setShowPopupAddOnsBricks (state, action) {
            state.showPopupAddOnsBricks = action.payload
        },

        setIsBricksOpen (state, action) {
            state.isBricksOpen = action.payload
        },
        setIsAreasOpen (state, action) {
            state.isAreasOpen = action.payload
        },
        setIsAreasForBuyBricks (state, action) {
            state.isAreasForBuyBricks = action.payload
        },

        setShowButtonBuyCoinsOnAdvertise (state, action) {
            state.showButtonBuyCoinsOnAdvertise = action.payload
        },
        setOrderListScrollPosition (state, action) {
            state.orderListScrollPosition = action.payload
        },

        setPreviewVisible (state, action) {
            state.previewVisible = action.payload
        },
        setResendInProcess
        (state, action) {
            state.resendInProcess = action.payload
        },

        setIsPreviousPathChooseAreas (state, action) {
            state.isPreviousPathChooseAreas = action.payload
        },
        setShowPopupScheduleFilters (state, action) {
            state.showPopupScheduleFilters = action.payload
        },
        setScheduleFilters (state, action) {
            state.scheduleFilters = action.payload
        },
        setButtonDriverAddOnsParent (state, action) {
            state.buttonDriverAddOnsParent = action.payload
        },
        setButtonDriverExtendLocation  (state, action) {
            state.buttonDriverExtendLocation = action.payload
        }

    }
})

export const {
    mainButtonOpening,
    mainButtonClosing,
    mainUnitFiltersOpening,
    mainUnitFiltersClosing,
    tuttorialsButtonOpening,
    tuttorialsButtonClosing,
    loadingRERAStart,
    loadingRERAEnd,
    setInitialData,
    dropInitialData,
    setScannedData,
    dropScannedData,
    setImages,
    dataFetchingStart,
    dataFetchingFinish,
    setDrawerOpen,
    setDynamicDropDown,
    setDocuments,
    dropModalSlice,
    subscriptionsButtonOpening,
    subscriptionsButtonClosing,
    ordersHistoryButtonOpening,
    ordersHistoryButtonClosing,
    deletingSomething,
    costomSelectClosing,
    costomSelectOpening,
    setIsUserVerified,
    setDateChooseViewing,
    setRescheduleReason,
    setShowPopupAddOnsAreas,
    setShowPopupAddOnsBricks,
    setIsBricksOpen, setIsAreasOpen,
    setIsAreasForBuyBricks,
    setShowButtonBuyCoinsOnAdvertise,
    setOrderListScrollPosition,
    setPreviewVisible,
    setResendInProcess,
    setIsPreviousPathChooseAreas,
    setShowPopupScheduleFilters,
    setScheduleFilters,
    setButtonDriverAddOnsParent, setButtonDriverExtendLocation
} = modalSlice.actions

export default modalSlice.reducer
