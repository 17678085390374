import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import UnitSh from './UnitSh'
import AgentFooter from '../../agentModule/AgentFooter'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import Loader from '../../../app/custom/loaders/Loader'
import _ from 'lodash'
import AgentHeader from '../../agentModule/AgentHeader'
import useUserType from '../../../hooks/UseUserType'
import LandlordHeader from '../../landlordModule/LandlordHeader'
import MyUnitsScrollSaver from './MyUnitsScrollSaver'
import { type Unit } from '../../../store/slices/unitsSlice'
import { USER_ID_KEY } from '../../../urls'
const MyUnitsSh = (): JSX.Element => {
    const { filters: storedFilters, searchString, unitsLoading, units, myArchivedUnitsOpen } = useAppSelector((state: RootState) => state.units)

    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? currentUser.id ?? ''

    const userDevice = useAppSelector((state) => state.app.userDevice)
    const { userType, userTypeLowercase } = useUserType()
    const [filteredUnits, setFilteredUnits] = useState<any>([])

    // console.log(myArchivedUnitsOpen, 'myArchivedUnitsOpen')
    // console.log(filteredUnits, 'filteredUnits')

    useEffect(() => {
        if (userType === 'Agent') {
            const agentUnits = units.filter((unit: Unit) => {
                if (myArchivedUnitsOpen) {
                    return unit?.attributes?.User?.data?.id === Number(userID) && unit?.attributes?.Statuses?.includes('Archived')
                } else {
                    return unit?.attributes?.User?.data?.id === Number(userID) && !unit?.attributes?.Statuses?.includes('Archived')
                }
            }) ?? []
            setFilteredUnits(agentUnits)
        } else {
            if ('Units' in currentUser) {
                const filters = _.cloneDeep(storedFilters)
                const filterCheckers: any[] = []

                for (let key in filters) {
                    let comparer: any = () => false
                    if (filters[key] !== undefined) {
                        const keyCopy = _.cloneDeep(key)
                        if (key[0] === '>') {
                            comparer = (filter: any[], field: any) => filter?.length > 0 ? _.includes(filter, field) : true
                            key = key.slice(1)
                        } else if (key[0] === '<') {
                            comparer = (filter: any, field: any[]) => _.includes(field, filter)
                            key = key.slice(1)
                        } else if (_.isArray(filters[key])) {
                            comparer = (filter: any[], field: any) => filter[0] <= field && field <= filter[1]
                        } else if (filters[key] === 'More') {
                            comparer = (filter: any, field: any) => field > 3
                        } else {
                            comparer = (filter: any, field: any) => field === filter
                        }
                        const keyJoined = key.split('_').join('.')
                        const check = (unit: any): boolean => {
                            return comparer(filters[keyCopy], _.get(unit, keyJoined))
                        }
                        filterCheckers.push(check)
                    }
                }
                filterCheckers.push((unit: any): boolean => {
                    if (searchString === '') {
                        return true
                    } else {
                        return unit.Number === +searchString ||
                            _.includes(unit.Property.Area.toLowerCase(), searchString.toLowerCase()) ||
                            _.includes(unit.Marketing.Headline.toLowerCase(), searchString.toLowerCase())
                    }
                })

                filterCheckers.push((unit: any): boolean => {
                    if (searchString === '') {
                        return true
                    } else {
                        return unit.Number === +searchString ||
                            _.includes(unit.Property.Area.toLowerCase(), searchString.toLowerCase()) ||
                            _.includes(unit.Marketing.Headline.toLowerCase(), searchString.toLowerCase())
                    }
                })
                filterCheckers.push((unit: any): boolean => {
                    if (_.includes(unit.Statuses, 'Archived')) {
                        return myArchivedUnitsOpen
                    } else {
                        return !myArchivedUnitsOpen
                    }
                })

                setFilteredUnits(
                    _.filter(currentUser.Units as any[],
                        (unit: any) =>
                            _.every(filterCheckers, (checker) =>
                                checker(unit) === true
                            )
                    ))
            } else {
                setFilteredUnits([])
            }
        }
    }, [units, myArchivedUnitsOpen])
    // currentUser, storedFilters, searchString,
    if (unitsLoading) return <Loader />
    return (
        <>
            <MyUnitsScrollSaver />
            <div className="page d-flex flex-column flex-row-fluid">
                {
                    userType === 'Agent'
                        ? <AgentHeader
                            active="My units"
                        />
                        : <LandlordHeader
                            active="My units"
                        />
                }

                <div className="content d-flex flex-column flex-fill p-0 m-4 mb-0">

                    {!unitsLoading && filteredUnits?.length > 0
                        ? (
                            filteredUnits.map((unit: any) => {
                                return (
                                    <UnitSh
                                        key={unit.id}
                                        hide={true}
                                        unit={userType === 'Agent' ? unit : { id: unit.id, attributes: unit }}
                                        fromEntry={'myUnitsAgent'}
                                    />
                                )
                            })
                        )
                        : (
                            <div className="card shadow-sm my-1">
                                <div className="card-body p-0 m-5 text-center">
                                    <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                                        {
                                            myArchivedUnitsOpen
                                                ? 'You have no archived units'
                                                : 'Let\'s add your first unit'
                                        }
                                    </span>
                                </div>
                            </div>
                        )
                    }
                    <div className="d-none"></div>
                </div>

                <div className="my-12" style={{ height: '11rem' }}></div>

            </div>
            {
                userType === 'Agent'
                    ? <AgentFooter />
                    : null
            }
            <div className="fixed-bottom mw-450px mx-auto px-4 py-2 bg-white" style={{ bottom: userDevice === 'IPhone' ? '9.65rem' : '8rem', left: '0px', zIndex: 50 }}>

                {userType === 'Agent'
                    ? <>
                        {/* <Link to={`/${userTypeLowercase}/modal/agent_create_unit/-/-`}> */}
                        <Link to={`/${userTypeLowercase}/modal/agent_create_unit_property/-/-`}>
                            <div className=" btn btn-bso my-1 fs-6 p-1 py-4 w-100">
                                Add unit
                            </div >
                        </Link>
                    </>

                    : <Link to={`/${userTypeLowercase}/set_unit_modal/set_unit_modal/-/-`}>
                        <div className=" btn btn-bso my-1 fs-6 p-1 py-4 w-100">
                            Add unit
                        </div >
                    </Link>
                }

            </div>
        </>
    )
}

export default MyUnitsSh
