import React from 'react'

import { type IUnitKey, type Unit } from '../../../store/slices/unitsSlice'
import { type IViewing } from '../../../store/slices/viewingsSlice'

import useUserType from '../../../hooks/UseUserType'
import TimelineDoorOpen from './viewingTimelines/timelineDoorOpen'
import TimelineOccupied from './viewingTimelines/timelineOccupied'
import TimelineBuildingManagement from './viewingTimelines/timelineBuildingManagement'
import TimelineDigitalLock from './viewingTimelines/timelineDigitalLock'
import TimelineKeysInBso from './viewingTimelines/timelineKeysInBSO'
import TimelineAgentToAgent from './viewingTimelines/TimelineAgentToAgent'
interface IProps {
    viewing: IViewing
    unit: Unit
    unitKey: IUnitKey
    isMyUnit: boolean
}

export interface TimelineItem {
    title: React.ReactNode
    description: React.ReactNode
    icon?: React.ReactNode
    index?: number
    status?: any

}

function ViewingTimeline({ viewing, unit, unitKey, isMyUnit }: IProps): JSX.Element {
    const { userType } = useUserType()
    const unitStatuses = unit.attributes.Statuses
    // console.log(unitStatuses, 'unitStatuses')
    let currentStatus = ''
    if (unitStatuses.includes('Digital lock')) {
        currentStatus = 'Digital lock'
    } if (unitStatuses.includes('Door open')) {
        currentStatus = 'Door open'
    } if (unitStatuses.includes('Keys in BSO office')) {
        currentStatus = 'Keys in BSO office'
    } if (unitStatuses.includes('Occupied')) {
        currentStatus = 'Occupied'
    } if (unitStatuses.includes('Building Management')) {
        currentStatus = 'Building Management'
    } if (unitStatuses.includes('Door close')) {
        currentStatus = 'Door close'
    }

    const statusComponentMapping = {
        'Digital lock': <TimelineDigitalLock viewing={viewing} unit={unit} unitKey={unitKey} userType={userType} />,
        'Door open': <TimelineDoorOpen viewing={viewing} unit={unit} unitKey={unitKey} userType={userType} />,
        'Keys in BSO office': <TimelineKeysInBso viewing={viewing} unit={unit} unitKey={unitKey} userType={userType} />,
        Occupied: <TimelineOccupied viewing={viewing} unit={unit} unitKey={unitKey} userType={userType} />,
        'Building Management': <TimelineBuildingManagement viewing={viewing} unit={unit} unitKey={unitKey} userType={userType} />,
        'Door close': <TimelineAgentToAgent viewing={viewing} unit={unit} unitKey={unitKey} userType={userType} isMyUnit={isMyUnit} />
    }

    const statusWithoutVacant = unitStatuses?.filter((status) => status === currentStatus)[0] as keyof typeof statusComponentMapping

    return (

        <div>
            {statusComponentMapping[statusWithoutVacant]}
        </div>
    )
}

export default ViewingTimeline
