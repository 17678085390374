
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import fillModalLink from '../../functions/agent/modals/fillModalLink'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { store, type RootState } from '../../store/store'
import { swalAttentionLeave, swalError, swalSuccess } from '../../functions/swalOptions'
import { type IResponseObjectJSON } from '../../types/userTypes/TAuth'
import { createLinksAdvertiseAction } from '../../store/actionsCreators/advertiseActions/createLinksAdvertiseAction'

import { reduceLinks, type StateLink } from '../../functions/agent/advertises/reduceLinks'
import { archiveUnit } from '../../store/actionsCreators/unitsActions'
import { deleteAgentUnit } from '../../store/actionsCreators/unitActions/deleteAgentUnit'
import { UniversalBuyBricks } from '../../components/shared/bricks/UniversalBuyBricks'

export default function CustomModalFooter({ modal, form, objectID, unitID }: any): JSX.Element {
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const modalID = modal[0].id

    const loading = useAppSelector(state => state.modal.loading)
    const service = useAppSelector((state) => state.service)
    const showButtonBuyCoinsOnAdvertise = useAppSelector((state: RootState) => state.modal.showButtonBuyCoinsOnAdvertise)

    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    let sumPay = 0
    for (const el of service.dop) {
        sumPay += Number(el.price)
    }
    const units = useAppSelector((state: RootState) => state.units.units)
    const statusUnit = units.filter((_unit) => _unit.id === Number(unitID))?.[0]?.attributes?.Statuses

    const antdSubmitHandler = async (callback: any): Promise<void> => {
        const currentAdvertise = store.getState().advertises.advertises?.filter((adv) => adv.id === Number(objectID))?.[0]
        const defaultLinks: StateLink[] = reduceLinks(currentAdvertise)
        if (form.getFieldValue('links').length === defaultLinks.length) {
            return
        }

        form
            .validateFields()
            .then(async () => {
                const json: IResponseObjectJSON = await dispatch(callback)
                if (json.isSuccessful) {
                    void swalSuccess(json.textNotification, () => {
                        navigate(json.textNavigate)
                    })
                } else {
                    void swalError(json.textNotification, () => {
                        navigate(json.textNavigate)
                    })
                }
            })
            .catch((errorInfo: any) => {
                //  console.log('Form errors:', errorInfo.errorFields)
            })
    }

    const deleteUnitHandler = async (): Promise<void> => {
        void swalAttentionLeave(
            statusUnit?.includes('Archived')
                ? 'Are you sure you want to delete the unit? It will be impossible to restore it'
                : 'Are you sure you want to move the object to the archive? You can restore it later'

        ).then(async (result: any) => {
            if (result.isConfirmed) {
                if (statusUnit.includes('Archived')) {
                    const resultDelete = await dispatch(deleteAgentUnit(Number(unitID)))
                    if (resultDelete.success && resultDelete.response) {
                        void swalSuccess('Unit deleted successfully', () => {
                            navigate('/agent/my_units')
                        })
                    }
                } else {
                    const resultArchive = await dispatch(archiveUnit(Number(unitID)))
                    if (resultArchive.success && resultArchive.response) {
                        void swalSuccess('Unit has archived successfully', () => {
                            navigate('/agent/my_units')
                        })
                    }
                }
            }
        }).catch(console.log)
    }

    const [isShowFooter, setIsShowFooter] = useState(true)
    const showPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingReschedule)

    useEffect(() => {
        if (showPopupViewingReschedule) {
            setIsShowFooter(false)
        } else {
            setIsShowFooter(true)
        }
    }, [showPopupViewingReschedule])

    return (
        <>
            <div
                className="footer fixed-bottom bg-white shadow-sm mw-450px mx-auto "
            >
                <div className={`d-flex flex-row w-100 h-100 p-2 ${userDevice === 'IPhone' ? 'pb-8' : 'pb-4'}`}>
                    {isShowFooter
                        ? modal?.[0]?.footer?.map((row: any) => (
                            <>
                                {row.id === 'delete_agent_unit' && !loading &&
                                    (
                                        <button type='button' onClick={async () => { await deleteUnitHandler() }} className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} ${loading ? ' disabled' : ''} border-bso2px p-4 w-100 mx-1`}>
                                            <span className="indicator-label">{row.content?.[0]}</span>
                                        </button>
                                    )
                                }
                                {row.element === 'button-primary' && row.id === 'open_list' && showButtonBuyCoinsOnAdvertise
                                    ? loading
                                        ? null
                                        : (
                                            <UniversalBuyBricks
                                                triggerButton={
                                                    <div
                                                        className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px py-4 px-2 w-100 mx-1`}
                                                    >
                                                        <span className="indicator-label">{row.content?.[0]} </span>
                                                    </div>
                                                }
                                                drawerKey="dashboard-parent-driver-bricks"
                                            />

                                        )
                                    : null
                                }

                                {row.element === 'button-primary' && row.id !== 'submit' && row.id === 'link' && (
                                    <Link to={fillModalLink()}>
                                        <div
                                            className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} me-2`}
                                        >
                                            <span className="indicator-label">{row.content?.[0]}</span>
                                        </div>
                                    </Link>
                                )}
                                {row.id === 'submit_antd' && (
                                    <button
                                        className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} border-bso2px py-4 px-2 w-100 mx-1`}
                                        onClick={() => { void antdSubmitHandler(createLinksAdvertiseAction({ form: form.getFieldsValue(), objectID, unitID })) }} >

                                        {loading
                                            ? <span className="indicator-progress d-block">
                                                Please wait...
                                                <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                            </span>
                                            : <span className="indicator-label">
                                                {row.content[0]}
                                            </span>
                                        }
                                    </button>
                                )}
                                {row.element === 'button-primary' && row.id === 'submit' && (
                                    <button type='submit'
                                        className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} ${loading ? ' disabled' : ''} border-bso2px py-4 px-2 w-100 mx-1 ${modalID === 'agent_advertise_create_duration' || modalID === 'agent_set_broadcast' ? showButtonBuyCoinsOnAdvertise ? 'opacity-50' : 'opacity-100' : ''}`}
                                        disabled={loading || showButtonBuyCoinsOnAdvertise}
                                    >
                                        {loading
                                            ? <span className="indicator-progress d-block">
                                                Please wait...
                                                <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                            </span>
                                            : row.content[0] === 'pay_now_id'
                                                ? <>
                                                    <span className="indicator-label">
                                                        {row.content?.[0] === 'pay_now_id'
                                                            ? `${service.plan?.oneTime ? `Get ${service.plan?.name}` : 'Subscribe'}
                                                            ${service.plan?.price !== undefined ? ` for AED ${service.plan?.price}` : ''}
                                                            ${service.plan?.oneTime ? '' : `/${service.plan?.dayString}`} 
                                                            ${sumPay > 0 ? `+ AED ${sumPay}` : ''}`
                                                            : row.content?.[0]}
                                                    </span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-4"></span>
                                                    </span>
                                                </>
                                                : <span className="indicator-label">
                                                    {row.content[0]}
                                                </span>
                                        }
                                    </button>
                                )}

                                {row.element === 'button-primary' && row.id === 'reset' && !loading &&
                                    (
                                        <button type={'reset'} className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} ${loading ? ' disabled' : ''} border-bso2px py-4 px-2 w-100 mx-1`}>
                                            <span className="indicator-label">{row.content?.[0]}</span>
                                        </button>
                                    )
                                }

                            </>

                        ))
                        : null
                    }

                </div>

            </div>
        </>
    )
}
