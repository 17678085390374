export const urlAPI = process.env.REACT_APP_BASE_URL_API as string
export const URL_API_SEND_NOTIFY = process.env.REACT_APP_BASE_URL_API_SEND_NOTIFY as string
export const urlAUTH = process.env.REACT_APP_BASE_URL_AUTH as string
export const urlAPIService = process.env.REACT_APP_BASE_URL_API_SERVICE as string
export const queryUSER = process.env.REACT_APP_BASE_URL_API_USER as string
export const urlSCANING = process.env.REACT_APP_URL_SCAN as string
export const URL_MAIN_API_APP = process.env.REACT_APP_BASE_URL_API_APP as string
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL as string
export const REACT_APP_BSO_USER_ID = process.env.REACT_APP_BSO_USER_ID as string
export const REACT_APP_BSO_SUPPORT_USER_ID = process.env.REACT_APP_BSO_SUPPORT_USER_ID as string
export const ACCESS_KEY = 'bso-access'
export const USER_ID_KEY = 'bso-user-id'
export const USER_EMAIL_KEY = 'bso-user-email'
export const USER_NUMBER_KEY = 'bso-user-number'
export const USER_CATEGORY_KEY = 'bso-user-category'
export const USER_NAME_KEY = 'bso-user-name'
export const USER_GENDER_KEY = 'bso-user-gender'
export const USER_DOB_KEY = 'bso-user-dob'
export const USER_NATIONALITY_KEY = 'bso-user-nationality'
export const USER_DOC_EXP_KEY = 'bso-user-doc-exp'
export const USER_DOC_NUM_KEY = 'bso-user-doc-num'

export const USER_ID_FRONT_ID_KEY = 'bso-user-doc-id-front-id'
export const USER_DOC_TYP_KEY = 'bso-user-doc-type'
export const USER_DOC_URL_KEY = 'bso-user-doc-link'

export const USER_ID_BACK_ID_LOAD = 'bso-user-doc-id-front-need-load-back'
export const USER_ADVERTISE_DAYS = 'bso-user-advertise-days'
export const USER_TIME_INTERVAL_VERIFY_KEY = 'bso-user-interval-verify'
export const USER_AREAS_EXTEND_COUNT = 'bso-user-areas-extend_count'
export const DEVICE_TOKEN_KEY = 'bso-device-token'
export const FIRST_TUTORIAL_KEY = 'bso-first-tutorial-show'

export const documentTypes: any = {
    visa: 'documents.passport',
    id_back: 'documents.emirates-id',
    id_front: 'documents.emirates-id',
    rera: 'documents.rera-card',
    agent_create_unit_title_deed: 'documents.title-deed',
    agent_edit_unit_title_deed: 'documents.title-deed',
    trade_license: 'documents.trade-license'
}

export const documentScanTypes: any = {
    visa: 'Passport',
    id_back: 'Emirates ID',
    rera: 'RERA Card',
    id_front: 'Emirates ID Front',
    agent_create_unit_title_deed: 'Title Deed',
    agent_edit_unit_title_deed: 'Title Deed',
    trade_license: 'Trade License'
}

export const prImage = '/assets/media/calendar_cards/statuses/In-process.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1mWwHKSUgKEF5OKgsyOSbYbSZf1EEYjQx&type=image/png'
export const rejImage = '/assets/media/calendar_cards/statuses/Rejected.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1bTHM6yk1qpL3SkF9TgWWvEIAFuP0G05a&type=image/png'
export const aprImage = '/assets/media/calendar_cards/statuses/Approved.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1vV-4uttyDkNbkAuwutL0ms_cUtGZzgGM&type=image/png'
export const othrImage = '/assets/media/calendar_cards/statuses/Other.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1k1DaZbbEvnm2pLE6_8B6f-XqO4bghXiM&type=image/png'
export const cancelledImage = '/assets/media/calendar_cards/statuses/Cancelled.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1wvLvkOBZiOPrFCe7OqjvtDUwFrWjo172&type=image/png'
export const completedImage = '/assets/media/calendar_cards/statuses/Completed.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1T42p3Cnm2tw_hemTfT0BWlYZmO4wNmQw&type=image/png'
export const negotiatedImage = '/assets/media/calendar_cards/statuses/Negotiated.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1iqNLQlctWxRFiB7upILwpPK-BCcaK8kf&type=image/png'
export const resubmitRequiredImage = '/assets/media/calendar_cards/statuses/Resubmit Required.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1rOuApp6kPk6Q8m3OfG-xNWKor598cZ6g&type=image/png'
export const confirmedImage = '/assets/media/calendar_cards/statuses/Confirmed.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1OiGUa57CFY64ItAmJowIiQ1SQhFZ3tAq&type=image/png'
export const closedImage = '/assets/media/calendar_cards/statuses_advertisements/Closed.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1eUGnbiXg784bHmMz4a46r1uy_ZI1CH9B&type=image/png'
export const docsReceivedImage = '/assets/media/calendar_cards/statuses_advertisements/Docs Received.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1VBjIQEJv5qjljF1XRAHLNDpDHkYLZFkZ&type=image/png'
export const linksAddedImage = '/assets/media/calendar_cards/statuses_advertisements/Links Added.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1ywvUXdN3U1lH_HuOTBY1ikNNzlRsP-ha&type=image/png'
export const linksApprovedImage = '/assets/media/calendar_cards/statuses_advertisements/Links Approved.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1-sgS6daheJ5alKhJdzCw8N5AFjlHrr51&type=image/png'
export const linksRejectedImage = '/assets/media/calendar_cards/statuses_advertisements/Links Rejected.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=12FFHBlmLytlsRhARg8eoEnbH_JBrj6Zv&type=image/png'
export const startedImage = '/assets/media/calendar_cards/statuses_advertisements/Started.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1FOTlgB4c5U7MrZ-5NqXtTjIVRcT87XIE&type=image/png'
export const todayImage = '/assets/media/calendar_cards/statuses/Today.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1lxDtTNpEYOfdrMW68jStHGDxjGyX2Jn_&type=image/png'

export const notTodayImage = '/assets/media/placeholders/schedule/No_active.png'



export const broadcastImageActive = '/assets/media/calendar_cards/statuses_broadcasts/Active.png'
export const broadcastImageCompleted = '/assets/media/calendar_cards/statuses_broadcasts/Completed.png'



export const InfoAnimationData = '/assets/media/animations/Info.json'
export const QuestionAnimationData = '/assets/media/animations/Question.json'
export const SuccessAnimationData = '/assets/media/animations/Success.json'
export const WarningAnimationData = '/assets/media/animations/Warning.json'
export const errorAnimationData = '/assets/media/animations/Error.json'

export const areaExpansionImage = '/assets/media/placeholders/addOns/area_expansion.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1r3pnqPNfghT-PXsXIQawJyDlZuSOZUKj&type=image/png'
export const locationSelectionImage = '/assets/media/placeholders/addOns/location_selection.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1viBGQsCl3dWdnTDGfLGlt68qCMqNTH66&type=image/png'
export const marketingCreditsImage = '/assets/media/placeholders/addOns/marketing_credits.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1DhPqzkUZU3O9k8j0je1YPFsgNiBTVSM5&type=image/png'

export const titleDeedInstructionImage = '/assets/media/placeholders/Tite Deed.png'
export const untitledImage = '/assets/media/placeholders/image_unavailable.png'



export const offersAttention = '/assets/media/calendar_cards/statuses_attention/Offer Notification.png'
export const viewingsAttention = '/assets/media/calendar_cards/statuses_attention/Viewing Notifications.png'
export const advertiseAttention = '/assets/media/calendar_cards/statuses_attention/Advertise Notifications.png'
export const placeholders = { 
    Messages:
   '/assets/media/placeholders/messages_placeholder.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1rHeDunGev-hIB6y8TdY9ns8sDK1iaofQ&type=image/png',
    Notices:
    '/assets/media/placeholders/schedule/Notice.png', //  'https://bso.ae/api/app/downloadStrapiFile?id=171noMP1zWIPB38a6f1yYCUx6bcS160sh&type=image/png',
    Viewings:
    '/assets/media/placeholders/schedule/No_active.png', //  'https://bso.ae/api/app/downloadStrapiFile?id=1_e91DlBVhTgGxLSoPkzbaLOFMMVe6qBd&type=image/png',
    Offers:
   '/assets/media/placeholders/schedule/Offer.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=13oPzIdVvEXwT14mm0HzalcIGHnOYW--e&type=image/png',
    Advertisements:
    '/assets/media/placeholders/schedule/Ad.png' ,//  'https://bso.ae/api/app/downloadStrapiFile?id=1zbCZolzRRPIepVw3GMT7BzNL1vqP9qBp&type=image/png'
    Broadcasts:
    '/assets/media/placeholders/schedule/Broadcast.png'  
}

export const scanningInstructions = {
    QR_Code:
'/assets/media/scan/scanning_instruction.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=11GGw6v5zchDldLEpOgmXolpbxs6wdZQ-&type=image/png',
    EID_Front:
'/assets/media/scan/EID.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=16ge9gdo1rsmySJ4UCY2XrvytpzVfzGPZ&type=image/png',
    EID_Back:
'/assets/media/scan/2.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1zyyewswZHZ6HugRqrjgtvhQGA99eQ5MR&type=image/png',
    Passport:
'/assets/media/scan/1.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1vOiowieKLY7x0Rh78UVSeQgo0c9GYJpY&type=image/png',
    RERA:
'/assets/media/scan/3.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1YtGq4wYs0Lm1wiyjhUE5jtkeaEp5cm3l&type=image/png'
}

export const tutorials = {
    'start-using-bso-club': {
        'slide-1': '/assets/media/tutorials/1STARTUSINGBSOCLUB/1.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1ZBwoEvEll5JNoDN8Dr8VHNRFt_tkV1C6&type=image/png'
        'slide-2': '/assets/media/tutorials/1STARTUSINGBSOCLUB/2.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1w8XsUVvt4luGPi_LXvUMPer04Mk26qXl&type=image/png'
        'slide-3': '/assets/media/tutorials/1STARTUSINGBSOCLUB/3.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=16exx0OjY_ZEXZIZj0niFH7aeZKAIK-PD&type=image/png'
        'slide-4': '/assets/media/tutorials/1STARTUSINGBSOCLUB/4.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1cgZxV-sLICg7RDuk8Du_w-bC83FzwEgF&type=image/png'
        'slide-5': '/assets/media/tutorials/1STARTUSINGBSOCLUB/5.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1A67RrmlQg5G4BPP5BdSOfe3WNE6u79pG&type=image/png'
    },

    'benefits-of-verified': {
        'slide-1': '/assets/media/tutorials/2BENEFITSOFVERIFIED/6.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1ig18Jac2J-Ihozj5YVl3XHKH9vrRQPu5&type=image/png'
        'slide-2': '/assets/media/tutorials/2BENEFITSOFVERIFIED/7.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1P_FhXHZPd9k0uk5Q5iarkLURY_Bwf9nX&type=image/png'
        'slide-3': '/assets/media/tutorials/2BENEFITSOFVERIFIED/8.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1kH9JFu00M2-FCmkv0rHxOtposTGVeEfN&type=image/png'
    },

    'advertise-bso-units': {
        'slide-1': '/assets/media/tutorials/3ADVERTISEBSOUNITS/9.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1Ukpk7MqfDk0SnxvygjFUNyWOXL5nP1wu&type=image/png'
        'slide-2': '/assets/media/tutorials/3ADVERTISEBSOUNITS/10.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1_LeYT-ib_xBv8KBqPSd_TOmpoo6KizGv&type=image/png'
        'slide-3': '/assets/media/tutorials/3ADVERTISEBSOUNITS/11.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=12CUnCxaIcmVjBcYxvl-N1zL-hkh5la-t&type=image/png'
        'slide-4': '/assets/media/tutorials/3ADVERTISEBSOUNITS/12.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=102IFRqL2eemm_qoW4mgTUjoGqWroTqBw&type=image/png'
        'slide-5': '/assets/media/tutorials/3ADVERTISEBSOUNITS/13.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1zqmys3RH_vI7TkDvCZ72CMurDtPV8Lfg&type=image/png'
    },

    'book-your-first-viewing': {
        'slide-1': '/assets/media/tutorials/4BOOKYOURFIRSTVIEWING/14.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=19lcmIJsr6DsfFODnPcamhhqiIXIc2_Qu&type=image/png'
        'slide-2': '/assets/media/tutorials/4BOOKYOURFIRSTVIEWING/15.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1jg9iNJYG96S_toxQyavEz5XMEi3h9i_N&type=image/png'
        'slide-3': '/assets/media/tutorials/4BOOKYOURFIRSTVIEWING/16.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1T1IDXOnInI9hcvTVUV1ql-F9Hr9BYl8K&type=image/png'
        'slide-4': '/assets/media/tutorials/4BOOKYOURFIRSTVIEWING/17.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1FtbnAyGJPFXTsnrydBmLfvZeecYaXewJ&type=image/png'
        'slide-5': '/assets/media/tutorials/4BOOKYOURFIRSTVIEWING/18.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1Tepo97WnO1Pw-zKiCYDJHCPKEpuP45ir&type=image/png'
        'slide-6': '/assets/media/tutorials/4BOOKYOURFIRSTVIEWING/19.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1e2cX3hhW9GymOls8YxymSxuCrA3mvR3_&type=image/png'
    },
    'business-scheduler-tasks': {
        'slide-1': '/assets/media/tutorials/5BUSINESSSCHEDULER&TASKS/20.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1EQqcCHlFc0hdqWT8pEKoY6vSmz9q8WZ5&type=image/png'
        'slide-2': '/assets/media/tutorials/5BUSINESSSCHEDULER&TASKS/21.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1YCGJcTCyOFl9_FsvECfM39eOvOcfM1au&type=image/png'
        'slide-3': '/assets/media/tutorials/5BUSINESSSCHEDULER&TASKS/22.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1XtRe41KOjNqf2RKgHg0qfPybuBEow-FO&type=image/png'
    },

    'submit-your-first-offer': {
        'slide-1': '/assets/media/tutorials/SUBMIT_YOUR_FIRST_OFFER/1.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=13icLaaQmYZNOg7HajCggFAjIVcirkR4H&type=image/png'
        'slide-2': '/assets/media/tutorials/SUBMIT_YOUR_FIRST_OFFER/2.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1ixxy30lSv-PdYR1RfD599Hz5E7YQbqi4&type=image/png'
        'slide-3': '/assets/media/tutorials/SUBMIT_YOUR_FIRST_OFFER/3.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1MeBArfC3cmmjfJuWvN6sgsSl2NrTqzse&type=image/png'
        'slide-4': '/assets/media/tutorials/SUBMIT_YOUR_FIRST_OFFER/4.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1Y57baDYMbVcgDVBJVT_UHjO5V25iUY3j&type=image/png'
        'slide-5': '/assets/media/tutorials/SUBMIT_YOUR_FIRST_OFFER/5.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1p3R-2JpMnCzF0EiMmiwWajRMAwgCoJ3O&type=image/png'
        'slide-6': '/assets/media/tutorials/SUBMIT_YOUR_FIRST_OFFER/6.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1FE7Mru3QyX8v3qTst97Zil4aYIcn5ynZ&type=image/png'
    },
    'bso-chat-magic': {
        'slide-1': '/assets/media/tutorials/BSO_CHAT_MAGIC/1.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=13AKHA-8hORq3pCMMLykVr0IuXHB7dugB&type=image/png'
        'slide-2': '/assets/media/tutorials/BSO_CHAT_MAGIC/2.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1s8BGALTWB7kmM21NqtWio3o43nfHeGsw&type=image/png'
        'slide-3': '/assets/media/tutorials/BSO_CHAT_MAGIC/3.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1KLOMe2iQt-z3mEwEF1l9nTrKfRAlVd89&type=image/png'
        'slide-4': '/assets/media/tutorials/BSO_CHAT_MAGIC/4.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1HWQFxBmislAtYapYwOV8TU9iq8v66myr&type=image/png'
        'slide-5': '/assets/media/tutorials/BSO_CHAT_MAGIC/5.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1QeF8HsEfWiBUroxAlYddwNFT331enQpl&type=image/png'
        'slide-6': '/assets/media/tutorials/BSO_CHAT_MAGIC/6.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1T30p4ybKjIcfZzSvnTSvOaz52SHNYWms&type=image/png'
        'slide-7': '/assets/media/tutorials/BSO_CHAT_MAGIC/7.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1rXrOqVzSDJahj_v5psN217j03MsR71v9&type=image/png'
        'slide-8': '/assets/media/tutorials/BSO_CHAT_MAGIC/8.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1pNowVXPjDAFR9jSeS-4EXlsY6WAozawl&type=image/png'
    },

    'bso-broker-rating': {
        'slide-1': '/assets/media/tutorials/BSO_BROKER_RATING/1.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1mCYbKSNzDKRXXRu8_5r3DpMN4pUjleQa&type=image/png'
        'slide-2': '/assets/media/tutorials/BSO_BROKER_RATING/2.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1vft5a_l5j3UP7YhcfHFvYfVWvaUVV7wm&type=image/png'
        'slide-3': '/assets/media/tutorials/BSO_BROKER_RATING/3.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1LPfEEHDPBJjS0gIKHjE11fIv--PcNzlx&type=image/png'
        'slide-4': '/assets/media/tutorials/BSO_BROKER_RATING/4.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1MayoBWBHZ7JRSKScv12ZQLOSKaTPItnf&type=image/png'
    },
    'bso-club-add-ons': {
        'slide-1': '/assets/media/tutorials/BSO_CLUB_ADD_ONS/1.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1Kum91kulsqS-WtnRZyUU418BS08U7UHx&type=image/png'
        'slide-2': '/assets/media/tutorials/BSO_CLUB_ADD_ONS/2.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=104dGUvc7CNGLiy47jN5wAjCcVPqJ9XV-&type=image/png'
        'slide-3': '/assets/media/tutorials/BSO_CLUB_ADD_ONS/3.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1HVoM73lm_NpeEjDWWBLJsL5-PrkyEq9s&type=image/png'
        'slide-4': '/assets/media/tutorials/BSO_CLUB_ADD_ONS/4.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=10hIPe5eUYi8cX1aXAFYAcFHns4BvzW3i&type=image/png'
        'slide-5': '/assets/media/tutorials/BSO_CLUB_ADD_ONS/5.png' // 'https://bso.ae/api/app/downloadStrapiFile?id=1OP3l8Fdt8bzozIoUWD5W9XjvVg9zjSmt&type=image/png'
    },

    thumbnails: {
        'slide-1': '/assets/media/tutorials/thumbnail/1.png', //  'https://bso.ae/api/app/downloadStrapiFile?id=1yRwjY6XWJwJKbuRUS9SbddVBmGh_5I4p&type=image/png',
        'slide-2': '/assets/media/tutorials/thumbnail/2.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1ZIez5NGM-lAzpFFvFIYzd68MtjDcVUaS&type=image/png',
        'slide-3': '/assets/media/tutorials/thumbnail/3.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=12JXxxgOSTDyxBup2-7mUfbdLvq76EcK9&type=image/png',
        'slide-4': '/assets/media/tutorials/thumbnail/4.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1eTGsjiM2tJHPK9kKk551YXx3vu2UNLAE&type=image/png',
        'slide-5': '/assets/media/tutorials/thumbnail/5.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1T5YKC5yPa25mjVmQd6ZcLuEuIW3ZdPwa&type=image/png',
        'slide-6': '/assets/media/tutorials/thumbnail/6.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1xH8pFF7Lz4iDPJ0KmPfFOpcNJB26CrMP&type=image/png',
        'slide-7': '/assets/media/tutorials/thumbnail/7.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1Tfu6lTx33ySZDQXwatsmb4FfyeLsnEQF&type=image/png',
        'slide-8': '/assets/media/tutorials/thumbnail/8.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1NV77BWOwn8aJa7PgEQaUNl1-PG5LZkDo&type=image/png',
        'slide-9': '/assets/media/tutorials/thumbnail/9.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1848vQ2O2G12CPoT6yXqFCWL4VxRZaZJ8&type=image/png',
        'slide-10': '/assets/media/tutorials/thumbnail/10.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1xpdlQnMryYStXpMlYovtQuU6dyqad-ZJ&type=image/png',
        'slide-11': '/assets/media/tutorials/thumbnail/11.png', // 'https://bso.ae/api/app/downloadStrapiFile?id=1Ppdv46hKgm-ruCkfqJelWMqoecZyP-Qk&type=image/png',
        'slide-12': '/assets/media/tutorials/thumbnail/12.png'// 'https://bso.ae/api/app/downloadStrapiFile?id=1lwm_S31XpNLgCJ1g55bRdwRL9Uy-zlFc&type=image/png'
    }

}

export const ava_blank = '/assets/media/ava_blank.jpg' // 'https://bso.ae/api/app/downloadStrapiFile?id=1Fb7SBHKELMyeLqe4ZmdoYoQe7mX4UBxe&type=image/jpeg'
export const bso_logo_512 = '/assets/media/logo512.jpg' //  'https://bso.ae/api/app/downloadStrapiFile?id=1IqWILwRH2_pC-fdUv6bY10JBlLGlyMso&type=image/png'
