import { type AppDispatch, type RootState} from '../store'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../urls'

import { animationOptions, swalOptions } from '../../functions/swalOptions'
import _ from 'lodash'
import {
    dataFetchingFinish,
    dataFetchingStart,
    dropModalSlice,

} from '../slices/modalSlice'

import swalConfig from '../../app/texts/swalTexts'


import {  registerLocale } from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import { offersSlice } from '../slices/offersSlice'
import { getErrorss } from '../../functions/errors/GetErrors'
import { fetchSingleUnit } from './unitsActions'
import Swal from 'sweetalert2'
import { type NavigateFunction } from 'react-router-dom'

import qs from 'qs'
import { type IOffer } from '../../types'
import { removeDataAttributes } from '../../functions/adapters/universal/removeDataAttributes'

import dayjs from 'dayjs'
import { setFilterNavigate } from '../slices/scheduleSlice'
import { offerAdminViewStatuses } from '../../constants'
import { removeNotifyByDeviceToken } from '../../functions/shared/notify-helpers/removeNotify'

import { getCurrentDubaiDate } from '../../functions/agent/date/getCurrentDateStrFormat'
import { getNameUser } from '../../functions/agent/agentModuleComponent/getNameUser'

import { type User } from '../slices/userSlice'
import { toUTCWithOffset } from '../../functions/agent/date/toUTCWithOffset'
import { getBelongsUnitForOffer } from '../../functions/shared/schedule/getBelongsUnit'
registerLocale(en)

export const addOffer = (data: any, type: 'residential' | 'commercial' | 'sale' | 'sale-commercial', unitID: number, offerID?: number, isEditDocument: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        dispatch(dataFetchingStart())
        const unit = await dispatch(fetchSingleUnit(unitID))
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        console.log(data, 'data')
        data.Type.__component = `offer.${type}`

        if ('ContractStartDate' in data.Type) {
            data.Type.ContractStartDate = dayjs(data.Type.ContractStartDate).add(dayjs(data.Type.ContractStartDate).utcOffset(), 'minutes').toISOString()
        }

        if (type === 'residential') {
            data.Type.Smoking = data.Type.Smoking === 'Yes'
            data.Type.Pets = data.Type.Pets === 'Yes'
        }
        if (type === 'sale' || type === 'sale-commercial') {
            data.Type.BankPreApproval = data.Type.BankPreApproval === 'Yes'
        }
        data.User = {
            id: getState().user.currentUser.id
        }
        data.Unit = {
            id: unitID
        }
        if ((type === 'residential' || type === 'commercial') && getState().modal.scannedData['Scanned.DepositSlipLink'] != null) {
            data.Type.DepositSlipLink = getState().modal.scannedData['Scanned.DepositSlipLink']
        }
        const areThereMoreThanFiveOffers = getState().offers.areThereMoreThanFiveOffers
        if (!areThereMoreThanFiveOffers) {
            dispatch(setFilterNavigate('offers'))
        }

        if (offerID == null) { // if not edit
            data.Statuses = [
                'In-process'
            ]
            data.Type = [{ ...data.Type }]

            data.Name = `Offer - ${String(unit?.Property?.data?.attributes?.Name)} - ${String(unit?.Marketing?.Headline)}`

            data.ProcessStartDate = new Date()

            data.Notify = 'Both'

            data.Documents = getState().modal.documents
            if (getState()?.modal?.scannedData?.['Scanned.TradeLicenceID'] != null) {
                data.Documents = [...data.Documents, getState()?.modal?.scannedData?.['Scanned.TradeLicenceID']]
            }
        } else {
            const offer = removeDataAttributes(
                _.cloneDeep(
                    getState().offers.offers.find((offer) => offer.id === offerID) ?? {}
                )
            )
            data.Statuses = [
                'In-process'
            ]
            if ((type === 'residential' || type === 'commercial') && data.Type.DepositSlipLink == null) {
                data.Type.DepositSlipLink = offer.Type[0].DepositSlipLink
            }
            data.Type = [{ ...data.Type }]
            data.Notify = 'Both'
            data.Documents = [...offer.Documents, ...getState().modal.documents]
            if (getState()?.modal?.scannedData?.['Scanned.TradeLicenceID'] != null) {
                data.Documents = [...data.Documents, getState()?.modal?.scannedData?.['Scanned.TradeLicenceID']]
            }
        }/* if (isEditDocument) {
            const offer = removeDataAttributes(
                _.cloneDeep(
                    _.find(getState().offers.offers, { id: offerID }) ?? {}
                )
            )
            if (offer != null) {
                offer.Type[0].FirstName = data.Type.FirstName
                offer.Type[0].FamilyName = data.Type.FamilyName
                offer.Type[0].Country = data.Type.Country
                offer.Type[0].EmiratesID = data.Type.EmiratesID

                data = offer
            }
        } */

        const bodyObject = {
            method: offerID != null ? 'PUT' : 'POST',
            collection: 'offers',
            id: offerID,
            body: _.omitBy(data, (value, key) => {
                //   console.log('KEY', key, 'VALUE', value)
                if (key?.includes != null) {
                    return key?.includes('Scanned.') || key?.includes('Type.') || ['state', 'Type.Country', 'Type.EmiratesID', 'Type.FirstName', 'Type.LastName', 'client_birthday', 'Scanned'].includes(key)
                }
                return ['state', 'Type.Country', 'Type.EmiratesID', 'Type.FirstName', 'Type.LastName', 'client_birthday', 'Scanned'].includes(key)
            }),
            query: qs.stringify({
                populate: ['Type', 'Documents', 'User', 'Unit']
            }, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        void dispatch(fetchOffers(true))
        dispatch(dataFetchingFinish())
        const responseJSON = await response.json()
        // if (offerID == null) {
        void sendNotification(new Date(new Date().getTime() + 10000), Number(getState().user.currentUser.id), responseJSON.response.data ?? {}, 'admin-offer-in-process', 'Offer - in process')
        // }
        responseJSON.isSuccessful = responseJSON.success
        responseJSON.textNotification = offerID == null ? 'Offer has been successfully submitted' : 'Offer has been successfully resubmitted'
        responseJSON.textNavigate = areThereMoreThanFiveOffers
            ? `/agent/modalView/offer_modal/${unitID}/${responseJSON?.response?.data?.id}/create?type=${type}`
            : '/agent/schedule'
        // '/agent/units/nav'
        dispatch(dropModalSlice())

        return responseJSON
    } catch (error) {
        console.log(error)
        return {
            isSuccessful: false
        }
    }
}

export const cancelOffer = (offerID: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const userID = window.localStorage.getItem(USER_ID_KEY)

    try {
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const offers = getState().user.currentUser.Offers ?? []
        const offer = _.find(offers, (offerItem: any) => offerItem.id === offerID)
        const ratingAffected = !_.includes(offer?.Statuses, 'In-process') && !_.includes(offer?.Statuses, 'Rejected')
        const ownerUnit = getBelongsUnitForOffer(offer)


        const iconText = 'Warning'
        const { isConfirmed } = await Swal.fire({
            ...swalOptions(iconText.toLowerCase()),
            title: swalConfig.attentionLeave.title,
            text: ratingAffected 
                ? swalConfig.offerCancelSwal.message.ratingAffected 
                : swalConfig.offerCancelSwal.message.ratingNotAffected,
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true ,
            confirmButtonText: swalConfig.offerCancelSwal.confirmButtonText,
            cancelButtonText: swalConfig.offerCancelSwal.denyButtonText,
            reverseButtons: false,
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../../animations/${iconText}.json`) }
                    })
                }
            }
    
        })


        if (!isConfirmed) {
            dispatch(dataFetchingFinish())
            return
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            id: offerID,
            body: {
                Statuses: ['Canceled'],
                Notify: ownerUnit.type === 'BSO UNIT' ? 'BSO' : 'Other Agent'
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        dispatch(dataFetchingFinish())
        const responseJSON = await response.json()
        responseJSON.isSuccessful = responseJSON.response.error == null
        if (responseJSON.isSuccessful) {
            const currentUser = getState().user.currentUser
            const nameUser = getNameUser(currentUser as User)
            await sendNotification(toUTCWithOffset(getCurrentDubaiDate()), Number(userID), responseJSON?.response?.data?.id, 'admin-offer-canceled', `Attention , ${nameUser} canceled offer.`)
        }

        return responseJSON
    } catch (error) {
        dispatch(dataFetchingFinish())
        console.log(error)
    }
}

export const setOfferStatus = (offerID: number, status?: string, data?: Record<string, any>, notify?: { rule: string, receiver: string }, slipLink?: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        if (status != null && ['Canceled', 'Rejected'].includes(status)) {
            const confirmationResult = await Swal.fire({
                ...swalOptions('warning'),
                title: 'Are you sure?',
                html: 'This offer will be canceled if you continue',
                showConfirmButton: true,
                confirmButtonText: 'Continue',
                showCancelButton: true,
                cancelButtonText: 'Go back'
            })
            if (!confirmationResult.isConfirmed) {
                return
            }
        }

        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const offer = removeDataAttributes(
            _.cloneDeep(
                _.find(getState().offers.offers, (offerItem: any) => offerItem.id === offerID) ?? {}
            )
        )

        if (data?.Type != null) {
            offer.Type = [{
                ...(offer?.Type[0] ?? {}),
                ...((data.Type as any[])?.[0] ?? {})
            }]
        }

        if (status !== undefined) {
            offer.Statuses = [status]
        }

        offer.Notify = !offerAdminViewStatuses.includes(String(status)) ? notify?.receiver : null

        if (slipLink !== undefined) {
            offer.Type[0].DepositSlipLink = slipLink
        }

        if (data != null && data?.Documents?.length > 0) {
            offer.Documents = [...offer.Documents, ...data.Documents]
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            id: offerID,
            body: offer,
            query: qs.stringify({
                populate: ['Type', 'User', 'Unit', 'Unit.Marketing']
            }, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        if (notify != null) {
            // const notifiedUserID = notify.receiver === 'BSO' ? 0 :
            //  console.log('OFFER', offer)
            // let notifyUserID: number
            /* if (notify.receiver === 'BSO') {
                notifyUserID = Number(process.env.REACT_APP_BSO_USER_ID)
            } else { */
            /* const units = getState().units?.units
            const unit = _.find(units, { id: offer?.Unit?.id })
            const notifyUserID = (unit as any)?.attributes?.User?.data?.id */
            // }

            await sendNotification(new Date(new Date().getTime() + 10000), offer?.User?.id, offer, notify?.rule, 'Offer - ' + String(status))
        }
        return await response.json()
    } catch (error) {
        console.log(error)
    }
}

export const saveAgentOfferNegotiate = (unitID: string | undefined, objectID: string | undefined) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    data.Type[0].ContractStartDate = dayjs(data.Type[0].ContractStartDate).add(dayjs(data.Type[0].ContractStartDate).utcOffset(), 'minutes').toISOString()
    void dispatch(setOfferStatus(Number(objectID), 'Negotiated by agent', data, {
        receiver: 'BSO',
        rule: 'admin-offer-negotiate'
    }))
    return {
        isSuccessful: true,
        textNotification: swalConfig.negotiateOfferSwal.negotiateNegotiated.message,
        textNavigate: -1
    }
}

export const removeOfferNotify = (offerID: number, newNotify: string | null = null) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const offers = _.cloneDeep(getState().offers?.offers ?? [])
        const offer: IOffer = _.find(offers, (offerItem: any) => offerItem.id === offerID) ?? { attributes: {} }
        offer.attributes.Notify = newNotify
        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            id: offerID,
            body: { ...offer.attributes, User: offer.attributes.User.data, Unit: offer.attributes.Unit.data }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.success === true) {
            void dispatch(offersSlice.actions.setOffers(offers))
        }
        await removeNotifyByDeviceToken({
            userID: getState().user.currentUser.id as number,
            deviceToken: getState().user.currentUser.DeviceToken as string
        })
    } catch (err) {
        console.log(err)
    }
}

export const fetchOffers = (noLoading: boolean = false, fetchAll: boolean = false) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    try {
        if (!noLoading) {
            dispatch(offersSlice.actions.offersFetching())
        }

        const query = {
            populate: {
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Unit: {
                    fields: ['id', 'Marketing'],
                    populate: {
                        Marketing: '*',
                        User: {
                            fields: ['id']
                        }
                    }
                },

                // Unit: {
                //     fields: ['id', 'Marketing'],
                //     populate: 'Marketing'
                // },
                Documents: {
                    fields: ['id']
                },
                Type: true
            }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'offers',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
            // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[Unit][0]=Marketing&[populate][Type]=*'
        }
        //   console.log(bodyObject, 'bodyObject')
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()
        // console.log(dataJSON, 'dataJSON Offers')
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                if (fetchAll) {
                    dispatch(offersSlice.actions.allOffersFetchingSuccess(dataJSON.response.data))
                } else {
                    dispatch(offersSlice.actions.offersFetchingSuccess(dataJSON.response.data))
                }
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(offersSlice.actions.offersFetchingError(JSON.stringify(error)))
        return { textNavigate: '', isSuccessful: false, textNotification: '' }

        //  dispatch(userSlice.actions.userFetchingError(error))
    }
}

export const scanDocumentsForOffer = (unitID: number, offerModalType: string, navigate: NavigateFunction, isEdit: boolean = false, offerID: number | string = '-') => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    //  console.log('SCAN DOCS')
    // const offer = _.find((getState().user.currentUser as any).Offers, offer => offerID === offer.id)

    // navigate(`/${(getState()?.user?.currentUser?.Category as string).toLowerCase()}/modal/agent_${isEdit ? 'edit' : 'set'}_offer_modal_${offerModalType}/${unitID}/${offerID}`)
    if (offerModalType === 'sale') {
        navigate(`/${getState()?.user?.currentUser?.Category as string}/modal/agent_to_bso_make_offer_documents/${unitID}/${offerID}`)
        // navigate(`/${(getState()?.user?.currentUser?.Category as string).toLowerCase()}/modal/agent_set_offer_modal_tenant_type_sale/${unitID}/${offerID}`)
    } else {
        navigate(`/${getState()?.user?.currentUser?.Category as string}/modal/agent_to_bso_make_offer_documents/${unitID}/${offerID}`)
        // navigate(`/${(getState()?.user?.currentUser?.Category as string).toLowerCase()}/modal/agent_set_offer_modal_tenant_type/${unitID}/${offerID}`)
    }
    // Deprecated logic
    /* function onChangeType (type: 'Emirates ID' | 'Passport'): void {
        const typeSwitch: HTMLInputElement | null = document.querySelector('#input-type-checkbox')
        const typeEmirates: HTMLDivElement | null = document.querySelector('#type-emirates-id')
        const typePassport: HTMLDivElement | null = document.querySelector('#type-passport')
        if (typeSwitch != null && typeEmirates != null && typePassport != null) {
            if (type === 'Emirates ID') {
                typeSwitch.checked = false
                typeEmirates.classList.add('btn-bso')
                typeEmirates.classList.remove('btn-light')
                typePassport.classList.add('btn-light')
                typePassport.classList.remove('btn-bso')
            } else {
                typeSwitch.checked = true
                typeEmirates.classList.remove('btn-bso')
                typeEmirates.classList.add('btn-light')
                typePassport.classList.remove('btn-light')
                typePassport.classList.add('btn-bso')
            }
        }
    }
    setTimeout(() => {
        const typeEmirates = document.querySelector('#type-emirates-id')
        const typePassport = document.querySelector('#type-passport')
        if (typeEmirates != null && typePassport != null) {
            typeEmirates.addEventListener('click', () => {
                onChangeType('Emirates ID')
            })
            typePassport.addEventListener('click', () => {
                onChangeType('Passport')
            })
        }
    })
    try {
        const response = await Swal.fire({
            ...swalOptions('info'),
            *//* ...{
                title: 'Scan the client\'s emirates ID or Passport',
                html: '<div>' +
                    'The scanning will be used to autofill the offer. <br> You can skip this step.' +
                    '</div>',
                showConfirmButton: true,
                confirmButtonText: 'Scan ID',
                showCancelButton: true,
                cancelButtonText: 'Process with no scanning',
                input: 'file',
                inputLabel: 'Upload ID',
                inputValue: ''
            } *//*
            ...{
                title: isEdit ? undefined : swalConfig.scanDocumentsForOfferSwal.title,
                html:
                    (isEdit
                        ? getOfferInfo(offer, swalConfig.scanDocumentsForOfferSwal.title)
                        : '') +
                    '<div class="mb-8">' +
                        swalConfig.scanDocumentsForOfferSwal.message(isEdit)[0] +
                        swalConfig.scanDocumentsForOfferSwal.message(isEdit)[1] +
                    '</div>' +
                    '<input style="display:none" type="checkbox" id="input-type-checkbox"/>' +
                    '<div class="d-flex gap-2 mb-2">' +
                        '<div id="type-emirates-id" class="btn btn-bso m-0 w-100">' +
                            swalConfig.scanDocumentsForOfferSwal.message(isEdit)[2] +
                        '</div>' +
                        '<div id="type-passport" class="btn btn-light m-0 w-100">' +
                        swalConfig.scanDocumentsForOfferSwal.message(isEdit)[3] +
                        '</div>' +
                    '</div>' +
                    '<input id="swal-input" type="file" class="form-control form-control-solid form-control-lg">',
                focusConfirm: false,
                // showDismissButton: true,
                showCancelButton: !isEdit,
                cancelButtonText: swalConfig.scanDocumentsForOfferSwal.cancelButtonText,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const cancelButton = Swal.getCancelButton()
                    if (cancelButton != null) {
                        cancelButton.style.display = 'none'
                    }
                    const documentType = (document.querySelector('#input-type-checkbox') as HTMLInputElement)?.checked ? 'Passport' : 'Emirates ID'
                    const fileList = (document.getElementById('swal-input') as HTMLInputElement)?.files
                    if (fileList != null && fileList?.length > 0) {
                        const response = await dispatch(scanDocuments(fileList, documentType))
                        if (response[0]?.response?.document?.valid === true) {
                            const document = response[0]?.response?.document
                            // const dateSplit = _(document.dob).split('').chunk(2).map((item: any) => item.join('')).value()
                            *//* const dateYear = +(dateSplit[0]) < 30 ? +dateSplit[0] + 2000 : +dateSplit[0]
                                    const dateMonth = +dateSplit[1] - 1
                                    const dateDay = +dateSplit[2] *//*
                            const initialState = {
                                'Type.FirstName': _.split(document.name, ' ')[1],
                                'Type.LastName': _.split(document.name, ' ')[0],
                                'Type.BusinessOwnerName': document.name,
                                'Type.Country': getName(document.nationality, 'en', { select: 'alias' }),
                                'Type.EmiratesID': document.number,
                                client_birthday: document.dob // moment(new Date(dateYear, dateMonth, dateDay)) // _(document.dob).split('').chunk(2).map(chunk => chunk.join('')).join('/')
                            }
                            dispatch(setInitialData({ initialState }))
                            navigate(`/${(getState()?.user?.currentUser?.Category as string).toLowerCase()}/modal/agent_${isEdit ? 'edit' : 'set'}_offer_modal_${offerModalType}/${unitID}/${offerID}`)
                            return true
                        }
                    }
                    if (cancelButton != null) {
                        cancelButton.style.display = 'block'
                    }
                    Swal.showValidationMessage(swalConfig.scanDocumentsForOfferSwal.validationMessage)
                    return false
                }
            }
        })
        if (response.value == null) {
            navigate(`/${(getState()?.user?.currentUser?.Category as string).toLowerCase()}/modal/agent_${isEdit ? 'edit' : 'set'}_offer_modal_${offerModalType}/${unitID}/${offerID}`)
        }
    } catch (err) {

    } */
}

export async function sendNotification (notificationTime: Date, userID: number, offer: IOffer, rule: string, text: string): Promise<void> {
    //  console.log(notificationTime, 'notificationTime')
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const sentBodyForNotification = {
        method: 'POST',
        collection: 'user-notifies',
        body: {
            mode: 'publish',
            executeAt: notificationTime,
            RuleID: offer.id,
            Rule: rule,
            User: userID,
            Text: text,
            Type: 'Mobile',
            publishedAt: null
        },
        query: qs.stringify({
            populate: ['User']
        }, {
            encodeValuesOnly: true
        })
    }

    const requestForNotification = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(sentBodyForNotification),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${String(jwt)}`
        }
    })

    // const requestForNotificationJSON = await requestForNotification.json()
    // console.log(requestForNotificationJSON, 'requestForNotificationJSON')
}

export const processSocketOffer = (entry: any) => (dispatch: AppDispatch, getState: () => RootState): { isSuccessful: boolean } => {
    try {
        const allOffers = getState().offers.offers
        const offer = _.find(allOffers, (offer) => offer.id === entry.id)
        const newOffer = {
            id: entry.id,
            attributes: {
                ...entry,
                Unit: {
                    data: {
                        id: entry.Unit.id,
                        attributes: {
                            Marketing: entry.Unit.Marketing
                        }
                    }
                },
                User: {
                    data: {
                        id: entry.User.id
                    }
                }
            }
        }
        if (offer == null) {
            dispatch(offersSlice.actions.setOffers([...allOffers, newOffer]))
        } else {
            dispatch(offersSlice.actions.setOffers(
                [
                    ...(_.filter(allOffers, (offerItem) => offerItem.id !== offer.id)),
                    newOffer
                ]
            ))
        }
        return {
            isSuccessful: true
        }
    } catch (err) {
        console.error(err)
        return {
            isSuccessful: false
        }
    }
}
