import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Icon from '../../icon/Icon'
import { type IconName, IconNames } from '../../icon/iconsConfig'
import { removeDataAttributes } from '../../../functions/adapters/universal/removeDataAttributes'
import dayjs from 'dayjs'
import {SpecialRequestSelectors} from "../../../store/slices/setUnitSlice";

export default function FormListForMyCustomForView ({ form, response }: { form: Record<string, any>, response: Record<string, any> }): React.ReactElement {
    const getFieldNameByKey = (key: string): string => {
        const row = form?.find((item: any) => item.id === key) ?? {}
        // setFormCopy(_.remove(form, (rowItem: any) => rowItem.id === row.id))
        const { content } = row
        return content ?? key
    }

    const isChangedFieldInNegotiatedOffer = (key: string): boolean => {
        return (
            ['Negotiated by BSO', 'Negotiated by agent', 'Negotiated by landlord'].includes(response?.Statuses?.[0]) &&
            ['Offer', 'Payments', 'Duration', 'ContractStartDate', 'AdditionalNotes'].includes(key)
        )
    }

    const dataSource = removeDataAttributes(_.cloneDeep(response))
    const attributes = form
        .map((row: { id: string }) => {
            /** Custom logic for complex fields */
            switch (row.id) {
                case 'unit_type':
                {
                    const titleDeedDoc = dataSource?.Documents
                        ?.sort((docA: any, docB: any) => docB.id - docA.id)
                        ?.find((doc: any) => doc?.Type?.[0]?.__component === 'documents.title-deed')

                    return [
                        row,
                        titleDeedDoc?.Type?.[0]?.Type
                    ]
                }
                case 'promotion_info':
                    return [
                        row,
                        [
                            dataSource?.Marketing?.DiscountPrice, dataSource?.Marketing?.DiscountStartDatetime, dataSource?.Marketing?.DiscountEndDatetime, dataSource?.Marketing?.Status === 'Enabled'].every(Boolean)
                            ? [
                                `Discount ${String(dataSource?.Marketing?.Status)}`,
                                `${String(dataSource?.Marketing?.DiscountPrice)} AED`,
                                `${dayjs(dataSource?.Marketing?.DiscountStartDatetime).format('DD MMM YYYY')} - ${dayjs(dataSource?.Marketing?.DiscountEndDatetime).format('DD MMM YYYY')}`
                            ]
                            : [
                                'Discount disabled'
                            ]
                    ]
                case 'Statuses>Mortgage':
                    if (dataSource?.Marketing?.Use === 'For sale') {
                        return [
                            row,
                            _.get(dataSource, row.id?.split('>')?.[0])?.includes(row.id?.split('>')?.[1])
                        ]
                    } else {
                        return [row, null]
                    }
                case 'current_rent':
                {
                    //    console.log(dataSource)
                    const offers = dataSource?.Offers
                    const offer = offers.find((offerItem: any) => {
                        const type = offerItem?.Type?.[0]
                        return dayjs(type?.ContractStartDate).add(type?.Duration, 'month').isAfter(dayjs())
                    })
                    const currentRent = _.get(offer, 'Type[0].Offer')
                    return [
                        row,
                        currentRent
                    ]
                }
                case 'DisabledTimes':
                    return [
                        row,
                        dataSource?.DisabledTimes?.map((range: any) => (
                            `${String(range.From.replace(':00.000', ''))}-${String(range.To.replace(':00.000', ''))}`
                        ))
                    ]
                case 'SpecialRequest':
                    return [
                        row,
                        Object.keys(dataSource?.SpecialRequest ?? {})
                            .filter((request: string) => dataSource?.SpecialRequest?.[request] === true)
                            .map((request: string) => SpecialRequestSelectors.find((selector) => selector?.value === request)?.label)
                    ]
                default:
                    if (row.id?.includes('>')) {
                        return [
                            row,
                            _.get(dataSource, row.id?.split('>')?.[0])?.includes(row.id?.split('>')?.[1])
                        ]
                    }
                    if (row.id?.includes(':')) {
                        return [
                            row,
                            _.get(dataSource, row.id?.split(':')?.[0])
                                ?.find((option: any) => row.id?.split(':')?.[1]
                                    ?.split(',')
                                    ?.includes(option))
                        ]
                    }
                    return [row, _.get(dataSource, row?.id) ?? null]
            }
        })
        .map((attribute: any) => {
            switch (dataSource?.Marketing?.Use) {
                case 'For rent':
                    if (['AnnualServiceCharge', 'DateServed', 'Statuses>Mortgage'].includes(attribute?.[0]?.id)) {
                        return null
                    }
                    break
                case 'For sale':
                    if (['CurrentRent', 'LegalNoticeServed'].includes(attribute?.[0]?.id)) {
                        return null
                    }
                    break
                default:
                    break
            }
            if (attribute?.[1] != null) {
                switch (attribute?.[0]?.format) {
                    case 'uppercase':
                        attribute[1] = attribute[1]
                            .split(' ')
                            .map((chunk: string) => (
                                chunk[0].toUpperCase() + chunk.slice(1)
                            ))
                            .join(' ')
                        break
                    case 'price':
                        attribute[1] = 'AED ' + String(attribute[1]?.toLocaleString('en-US') ?? 'Not set')
                        break
                    case 'number':
                        attribute[1] = String(attribute[1]?.toLocaleString('en-US') ?? 'Not set')
                        break
                    default:
                        break
                }
                return attribute
            }
            return [
                attribute?.[0],
                'Not set'
            ]
        })
        .filter(Boolean)

    return (
        <div>
            {attributes.map(([row, value]: [Record<string, any>, any]) =>
                // !(value == null || (Array.isArray(value) && value.length === 0))
                //  ? (
                // border-bottom-dashed border-gray-200
                <div key={nanoid()} className="fv-row mb-2 m-0 p-0 w-100   ">
                    <div
                        className={`d-flex justify-content-between align-items-center ${isChangedFieldInNegotiatedOffer(row?.id) ? 'badge-light-primary border border-primary' : ''}`}
                        style={isChangedFieldInNegotiatedOffer(row?.id)
                            ? { margin: '0 -5px', padding: '0 5px', borderRadius: '0.425rem' }
                            : {}
                        }
                    >

                        <label className={'d-flex align-items-center form-label fw-bold fs-4 text-dark m-0'}>
                            <Icon name={
                                IconNames.includes(row?.icon)
                                    ? row?.icon
                                    : 'home'
                            } className={'me-2 fs-3x text-dark'} styles={{ minWidth: '2.1rem' }}
                            />
                            {/* form.find((item: any) => item.id === key)?.content || key */}
                            {getFieldNameByKey(row?.id)?.[0]}
                        </label>
                        <div className={'d-flex gap-2'}>
                            {value != null && value !== 'Not set' ? getFieldNameByKey(row.id)?.[1] : ''}
                            {[
                                'DisabledWeekdays',
                                'Features',
                                'Amenities',
                                'Appliances',
                                'Statuses',
                                '_Statuses',
                                'Areas',
                                'Unit Statuses',
                                'promotion_info',
                                'DisabledTimes',
                                'SpecialRequest'
                            ].includes(row.id)
                                ? (
                                    Array.isArray(value) && value.length > 0
                                        ? (
                                            <>
                                                <div className="d-flex flex-wrap justify-content-end fw-semi-bold fs-4 text-dark gap-1">
                                                    {value.map((el) => {
                                                        return (
                                                            <div key={el}
                                                                className="d-flex btn btn-sm btn-light p-1"
                                                            >
                                                                {/* <span className="bi bi-tag-fill pe-1"></span> */}
                                                                <Icon name={'label'} className={'pe-1 text-muted'} useWrapper />
                                                                <span>{el}</span>
                                                            </div>

                                                        )
                                                    }

                                                    )}
                                                </div>
                                            </>
                                        )
                                        : typeof value === 'string' ? value : 'Not set'
                                )
                                : (
                                    <>
                                        <div className="fw-normal text-end text-dark">

                                            {typeof value === 'object'
                                                ? JSON.stringify(value)
                                                : value === true
                                                    ? 'Yes'
                                                    : value === false
                                                        ? 'No'
                                                        : dayjs(value, 'YYYY-MM-DD').isValid()
                                                            ? dayjs(value).format('DD MMM YYYY')
                                                            : (value ?? 'Not set').toString()}
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
                // )
                // : null
            )}
        </div>
    )
}
