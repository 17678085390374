import React from 'react'
// import TabButton from '../../shared/elements/TabButton'
import { useSearchParams } from 'react-router-dom'
import SearchMessages from './search-results/messages'
import SearchHeader from './header'
// import SearchUnits from './search-results/units'
import SearchProfiles from './search-results/profiles'
import SearchInvites from './search-results/invites'
import SearchUnits from './search-results/units'
import { useAppSelector } from '../../../hooks/redux'
const Search = (): JSX.Element => {
    const [searchParams] = useSearchParams()
    const type = searchParams.get('type') ?? 'profiles'
    const userDevice = useAppSelector((state) => state.app.userDevice)
    return (
        <div className={'page d-flex flex-column flex-row-fluid w-100'} style={{ height: userDevice === 'IPhone' ? 'calc(100vh - 9.5rem)' : 'calc(100vh - 8rem)' }}>
            <SearchHeader/>
            {
                (type === 'messages') && (
                    <SearchMessages/>
                )
            }
            {
                (type === 'units') && (
                    <SearchUnits/>
                )
            }
            {
                (type === 'profiles') && (
                    <SearchProfiles/>
                )
            }
            {
                (type === 'invites') && (
                    <SearchInvites/>
                )
            }
        </div>
    )
}

export default Search
